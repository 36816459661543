/* caroussel */
#caroussel1 {
  background: url(../images/bg1.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

#caroussel3 {
  background: url(../images/bg2.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

#btnContactCarousel {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;

  &:hover {
    transition: 0.3s;
    background-color: #e93f33;
    color: white;
    border: none;
  }
}

@media (width<990px) {
  .atom-main-slider .banner-info h3 {
    margin-top: 20px;
  }

  #btnContactCarousel {
    margin-bottom: 20px;
  }
}
/* caroussel */

/* aboutSection */
#slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.atom-index2 {
}

.titleAboutHome,
.textAboutHome {
  text-align: left;
}

.titleAboutHome1 {
  text-align: left;
  color: #e93f33;
  font-weight: bold;
}

.atom-two-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: -20px;
}

.btnReadMoreAboutHome {
  background-color: #e93f33;
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding-top: 5px;
  /* padding-bottom: 5px; */
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;

  &:hover {
    background-color: #e93f33;
    color: white;
  }
}

.btnContactUsAboutHome {
  text-decoration: none;
  color: #e93f33;
  border: #e93f33 solid 2px;
  font-weight: bold;
  /* padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px; */
  border-radius: 8px;

  &:hover {
    transition: 0.5s;
    background-color: #e93f33;
    color: white;
  }
}

.imageAboutHome {
  /* height: 900px; */
}

@media (width <= 995px) {
  .atom-two-buttons {
    padding-bottom: 20px;
  }

  .imageAboutHome {
    width: 100%;
  }
}

/* aboutSection */

/* services */
#titleService {
  text-align: center;
}

.linkService {
  text-decoration: none;
}
/* services */

/* What we do */
.whatWeDoHome {
  text-align: justify;
}

.btnReadMoreAboutHome2 {
  margin-top: -10px;
}
/* What we do */

/* Newsletter */
.firstBlockNewsletter input[type="email"] {
  background: var(--bg-color);
  border: none;
  padding: 15px 15px;
  outline: none;
  width: 100%;
  color: var(--font-color);
  border: none;
  border: 1px solid var(--bg-color);
  border-radius: var(--border-radius);
}

.firstBlockNewsletter input[type="email"]:focus {
  border: 1px solid var(--secondary-color);
}

.firstBlockNewsletter button {
  text-align: center;
  background: transparent;
  padding-left: 0;
  border: none;
  outline: none;
  color: var(--secondary-color);
  font-size: 18px;
  cursor: pointer;
  border-radius: 0;
  position: absolute;
  right: 15px;
  top: 15px;
}

.firstBlockNewsletter button:hover {
  color: var(--primary-color);
  box-shadow: none;
  background: transparent;
}

.firstBlockNewsletter {
  display: flex;
  margin-top: 5px;
  position: relative;
}

/* Newsletter */

/* statistique */

.blockStatistique {
  background-image: url("../images/g8.jpg");
  margin-top: 10%;
  background-repeat: no-repeat;
  background-size: cover;
}

.opacityBlockStatistique {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.651);
  padding-top: 70px;
  padding-bottom: 50px;
}

.chiffreStatistique {
  font-size: 50px;
  color: white;
  font-weight: bold;
  text-align: left;
}

.textStatistique {
  color: white;
  font-size: 20px;
  text-align: left;
}

.projetStatistique {
  margin-bottom: 20px;
}

@media (width <= 995px) {
  .textStatistique {
    text-align: center;
  }

  .chiffreStatistique {
    text-align: center;
  }
}

/* statistique */
