#headerContactPage {
  background: url(../images/imagebg3.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  z-index: 0;
}

#title2ContactPage {
  text-align: center;
}

#btnSendMessageContact {
  border: none;

  &:hover {
    background-color: #be382e;
  }
}

.informationsContact {
  text-align: left;
}
#informationsContact {
  text-align: left;
  color: #e93f33;
  font-weight: bold;
  font-size: 20px;
}
