.slider-container {
  width: 100%;
  margin-bottom: 20px;
}

.logoIlNousFontoConfiance {
  width: 150px;
  height: 120px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.308) 0px 2px 5px 5px;
}

.titleIlsNousFontConfiance {
  color: #e93f33;
  font-weight: bold;
  margin-bottom: 10%;
}

@media (width <= 500px) {
  .logoIlNousFontoConfiance {
    width: 100px;
    height: 70px;
    /* margin-left: 90px; */
    margin-right: 90px;
  }

  .slider-logo {
    width: 93%;
    margin-left: auto;
    margin-right: auto;
  }
}
