/* @import url(https://fonts.googleapis.com/css?family=opensans:500); */
.blockNotFound {
  background: #e93f3391;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.code404 {
  color: white;
  font-weight: bold;
  font-size: 100px;
  padding-bottom: 20px;
  margin-top: 40vh;
}

hr {
  /* width: 50%; */
  margin-left: auto;
  margin-right: auto;
}

.text404 {
  color: #e93f33;
  text-decoration: dotted;
  font-weight: bold;
}

.link404 {
  text-decoration: none;
  background-color: #e93f33;
  border-radius: 8px;
  font-size: 20px;
  color: white;
  font-weight: bold;
  padding: 5px 5px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.432) 2px 2px 2px 2px;

  &:hover {
    color: white;
    background-color: #c0433a;
    transition: 0.3s;
  }
}
