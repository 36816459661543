.w3pt-call-info {
  text-align: left;
}

.footerLinks {
  text-align: left;
}

.atom-footer-29-main {
  margin-top: 100px;
}
