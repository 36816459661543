#headerAboutPage {
  background: url(../images/imagebg4.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 0;
}

.titleAboutPage {
  font-family: "Hind Siliguri", sans-serif;
  color: white;
  font-weight: bold;
  margin-top: 20px;
}

.titleSectionAbout {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #e93f33;
}

#w3breadcrumb-right {
  margin-left: auto;
  margin-right: auto;
}

.textAboutus {
  text-align: left;
}

#textAboutus {
  text-align: left;
}

#textAboutus a {
  text-decoration: none;
}

.textSectionAbout {
  text-align: justify;
}

#titleQueFaisonsNous {
  font-size: 35px;
}

#blockStatistiqueAbout {
  margin-top: -50px;
}
